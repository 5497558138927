import * as React from 'react';
import Lottie from 'react-lottie';
import * as animationData from '../../../../animations/cloudkeyz-intro.json';
import Header from '../../../Header/Header';
import ProjectAppLinks from '../../../UI/ProjectAppLinks/ProjectAppLinks';
import ProjectBlock from '../../../UI/ProjectBlock/ProjectBlock';
import ProjectFooter from '../../../UI/ProjectFooter/ProjectFooter';
import ProjectGoals from '../../../UI/ProjectGoals/ProjectGoals';
import ProjectIntro from '../../../UI/ProjectIntro/ProjectIntro';

import './CloudKeyz.sass';

const challenges = [
  'Video and audio calls 2 way between Intercom and mobile phones',
  'Animation and in-app sound for an excellent user experience',
  'Management software for controlling access from the cloud',
  'Bluetooth & Wifi communication layers between products',
  'Firmware for the door controllers and video intercom',
  'Geo fencing, real-time image processing, offline mode support',
  'Top notch security standards to ensure personal data under protection',
];

const solutions = [
  'The New Access Point Technology',
  'Innovative Mobile Keychain',
  'Property Access Management Reinvented',
  'New Kind of Names Directory',
  'Instantly issue and revoke mobile access',
  'Access Awareness in Real-Time',
  'STOP hanging ugly paper notices around your property',
  'Send messages to property management;',
  'Restricted access on a one-time or periodic basis to babysitters, cleaning services, gardeners, etc;',
  'Keyless Convenience: one simple solution to access everything;',
  'Knock, Talk & Video Communication in real time;',
  'Control Property Security.',
];

class CloudKeyz extends React.Component {

  render() {

    const info = [
      { label : 'Client', value : 'The CloudKeyz Inc' },
      { label : 'Year', value : '2019' },
      { label : 'Category', value : 'Web App / Mobile App / Website' },
      { label     : 'Visit Website',
        value     : 'http://www.cloudkeyz.com',
        linkLabel : 'cloudkeyz.com',
        isLink    : true,
      },
    ];

    const lottieOptions = {
      loop             : true,
      autoplay         : true,
      animationData    : animationData.default,
      rendererSettings : {
        preserveAspectRatio : 'xMidYMid slice',
      },
    };

    return (
      <main className='cloudkeyz'>
        <Header/>
        <ProjectIntro title='CloudKeyz.'
                      description='Access Control platform that gives residents, employees, vendors & guests an easy way to access community doors, gates and garages using mobile app, codes or key fobs. Product suite consist of management software, multiple mobile apps and firmware for hardware devices.'
                      heading='heading-case'
                      info={info}>
          <div className='project-intro__animation'>
            <Lottie options={lottieOptions} height='100%' width='100%'/>
          </div>
        </ProjectIntro>
        <ProjectGoals challenges={challenges} solutions={solutions}/>
        <ProjectBlock className='cloudkeyz-ecosystem'
                      heading='heading-num1'
                      title='The CloudKeyz Platform'
                      subtitle='CloudKeyz opens doors, gates, garages, elevators and any other electronic door with keyless mobile technology, access code or keyfob. Sophisticated and complex technology transformed into set of easy to use tools for property managers, operators and end users.'
                      centered
                      overlappedHeading
        >
          <img src='../../../../images/projects/cloudkeyz/ecosystem.png'
               alt='Illustration: use cases'/>
        </ProjectBlock>
        <ProjectBlock className='cloudkeyz__mobile-app'
                      heading='heading-num2'
                      title='Keyless Access Mobile App'
                      subtitle='Mobile app UI adapts to where user are, how close it is to the property and how many doors the property has. It uses advanced triangulation techniques to determine location underground and can communicate with CloudKeyz controllers and readers via bluetooth and wifi.'
                      image={{
                        src : '../../../../images/projects/cloudkeyz/mobile-app.png',
                        alt : 'Image: 2 phones with mobile application',
                      }}/>
        <ProjectBlock className='cloudkeyz__cloud-screen'
                      heading='heading-num3'
                      title='Cloud Screen'
                      subtitle='Cloud Screen is a video intercom with a two-way video call, access codes and key fob reader for vendors and guests. It is a completely custom product, remotely controlled, yet designed to provide a fully working solution in offline mode.'
                      isImageFirst={true}
                      image={{
                        src : '../../../../images/projects/cloudkeyz/cloud-screen.png',
                        alt : 'Image: Cloud Screen connected with mobile app to access',
                      }}/>
        <ProjectBlock className='cloudkeyz__management'
                      heading='heading-num4'
                      title='Online Management Software'
                      subtitle='Property managers and operators can instantly issue and revoke access for employees, tenants, guests and vendors. Detailed access history and analytics combined with CCTV camera integrations gives them unparalleled control over their real estate portfolio.'
                      centered
                      overlappedHeading
                      image={{
                        src : '../../../../images/projects/cloudkeyz/access-management.png',
                        alt : 'Image: screens with management admin panel',
                      }}/>
        <ProjectBlock className='cloudkeyz__service-app'
                      heading='heading-num5'
                      title='Service App'
                      subtitle='CloudKeyz Service App is made for installers or integrators, allowing them to setup and manage hardware controllers and cloud screens. It operates via bluetooth and internet and provides a convenient and efficient way to service and troubleshoot hardware on site and remotely.'
                      isImageFirst={true}
                      image={{
                        src : '../../../../images/projects/cloudkeyz/service-app.png',
                        alt : 'Image: 3 smartphones with service app using',
                      }}/>
        <ProjectAppLinks icon='cloudkeyz-small'
                         title='CloudKeyz App'
                         message='Download the free application and get the keyless access to your property. Available on the AppStore and GooglePlay.'
                         appStoreLink='https://apps.apple.com/us/app/cloudkeyz-app/id1345241883'
                         googlePlayLink='https://play.google.com/store/apps/details?id=com.cloudkeyz.app&hl=en'
                         className='cloudkeyz__links'
        />
        <ProjectFooter title='1File'
                       className='cloudkeyz__footer'
                       link='/1file'
                       imageSrc='../../../../images/projects/onefile-footer.png'
                       imageAlt='Image: polygonal sphere with links'
        />
      </main>
    );
  }
}

export default CloudKeyz;

import React, { Component } from "react"
import PageWrapper from "../components/PageWrapper/PageWrapper"
import SEO from "../components/seo"
import CloudKeyz from "../components/Pages/Projects/CloudKeyz/CloudKeyz"

class CloudkeyzPage extends Component {
  render() {
    return (
      <PageWrapper>
        <SEO
          title='CloudKeyz'
          description='Access Control platform that gives residents, employees, vendors & guests an easy way to access community doors, gates and garages using mobile app, codes or key fobs. Product suite consist of management software, multiple mobile apps and firmware for hardware devices.'
        />
        <CloudKeyz/>
      </PageWrapper>
    )
  }
}

export default CloudkeyzPage
